/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useState, useCallback } from 'react';
import { FaStar, FaStarHalf } from 'react-icons/fa';
import style from './PuntuationReview.module.scss';
import { useMemo } from 'react';

function PuntuationReview({ number, scoring, viewMedia, onClick }) {
   // Enviar scoring si está puntuado y number si muestra la review únicamente (No ambas)

   const [pint, setPint] = useState(0);
   const [hover, setHover] = useState(0);

   const StarIcon = useCallback(
      ({ index }) => {
         const condition = () => {
            if (pint >= index || hover >= index || number - index > -0.5) {
               return (
                  <FaStar
                     onMouseEnter={() => scoring && setHover(index)}
                     onMouseLeave={() => scoring && setHover(0)}
                     onClick={() => {
                        if (scoring) {
                           setPint(index);
                           scoring.setState({ ...scoring.state, rating: index });
                        }
                     }}
                     className={`${style['star-full']} ${scoring && style.scoring}`}
                     size={18}
                  />
               );
            }
            if (!scoring && number - index > -1) {
               return (
                  <div className={style['half-star']}>
                     <div>
                        <FaStarHalf className={style['star-full']} size={18} />
                        <FaStar className={style['star-empty']} size={18} />
                     </div>
                  </div>
               );
            }
            return (
               <FaStar
                  onMouseEnter={() => scoring && setHover(index)}
                  onMouseLeave={() => scoring && setHover(0)}
                  onClick={() => {
                     if (scoring) {
                        setPint(index);
                        scoring.setState({ ...scoring.state, rating: index });
                     }
                  }}
                  className={`${style['star-empty']} ${scoring && style.scoring}`}
                  size={18}
               />
            );
         };
         return <>{condition()}</>;
      },
      [setHover, setPint, scoring],
   );

   return (
      <>
         <div onClick={onClick} className={style['review-container']}>
            {number >= 0 && viewMedia && <h4 className={style.number}>{number}</h4>}
            <span className={style['stars-review']}>
               <StarIcon index={1} />
               <StarIcon index={2} />
               <StarIcon index={3} />
               <StarIcon index={4} />
               <StarIcon index={5} />
            </span>
         </div>
         {scoring?.state.sendBtn && scoring?.state.rating === 0 && (
            <span className={style.errorMessage}>Este campo es obligatorio</span>
         )}
      </>
   );
}
export default PuntuationReview;
