import useMetatagsData from '../../hooks/useMetatagsData';

export default function renderHeadDescription(price, product, colorSelected) {
   const { STORE_NAME } = useMetatagsData();
   const name = product?.name;
   price = price?.sale_price ? price.sale_price : price?.original;
   colorSelected = colorSelected ? colorSelected.color?.toLowerCase() || '' : '';

   return `¡Comprá ahora! ${name} ${colorSelected} a $${price} - Pagá en cuotas sin interés en ${STORE_NAME} - Envío gratis a todo el país.`;
}
