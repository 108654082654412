/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
import React, { useEffect, useState } from 'react';
import CarouselProducts from 'CarouselProducts';
import PlaceholderCarousel from 'PlaceholderCarousel';
import API from '../../../api';
import styles from './carousel.module.scss';

export default function RelatedProducts({ productId, storeId }) {
   const [otherPeopleSawLoading, setOtherPeopleSawLoading] = useState(true);
   const [toCombineLoading, setToCombineLoading] = useState(true);
   const [otherPeopleSaw, setOtherPeopleSaw] = useState([]);
   const [toCombine, setToCombine] = useState([]);

   const customersAlsoViewed = async () => {
      const result = await API.products.getCustomersAlsoViewed(productId, storeId);
      if (result.success) {
         setOtherPeopleSaw(result.data);
         setOtherPeopleSawLoading(false);
      }
   };

   const wearItWith = async () => {
      const result = await API.products.getWearItWith(productId, storeId);
      if (result.success) {
         setToCombine(result.data);
         setToCombineLoading(false);
      }
   };

   useEffect(() => {
      customersAlsoViewed();
      wearItWith();
   }, [productId]);

   return (
      <>
         {toCombine.length > 0 && (
            <div className={styles.wrapper}>
               <h2 className={styles.title}>Combinalo con</h2>
               {toCombineLoading && <PlaceholderCarousel />}
               {!toCombineLoading && <CarouselProducts products={toCombine} withQuickView />}
            </div>
         )}
         {otherPeopleSaw.length > 0 && (
            <div className={styles.wrapper}>
               <h2 className={styles.title}>Quienes vieron este producto también vieron</h2>
               {otherPeopleSawLoading && <PlaceholderCarousel />}
               {!otherPeopleSawLoading && <CarouselProducts products={otherPeopleSaw} />}
            </div>
         )}
      </>
   );
}
