import React, { useState, useRef, useEffect } from 'react';
import { FiChevronDown } from 'react-icons/fi';
import style from './Accordion.module.scss';

function Accordion({ title, children }) {
   const content = useRef(null);

   const [setActive, setActiveState] = useState('active');
   const [setHeight, setHeightState] = useState('0px');
   const [setRotate, setRotateState] = useState('rotate');

   function toggleAccordion() {
      setActiveState(setActive === '' ? 'active' : '');
      setHeightState(setActive === 'active' ? '0px' : `${content.current.scrollHeight}px`);
      setRotateState(setActive === 'active' ? '' : 'rotate');
   }
   useEffect(() => {
      setActiveState('active');
      setHeightState(`${content.current.scrollHeight}px`);
      setRotateState('rotate');
   }, [children]);
   return (
      <div className={style.accordion__section}>
         <button className={`${style.accordion} ${style[setActive]}`} onClick={toggleAccordion}>
            <h3 className={style.accordion__title}>{title}</h3>
            <i>
               <FiChevronDown
                  size={15}
                  className={`${style.accordion__icon} ${style[setRotate]}`}
               />
            </i>
         </button>
         <div
            ref={content}
            style={{ maxHeight: `${setHeight}` }}
            className={style.accordion__content}
         >
            {children}
         </div>
      </div>
   );
}

export default Accordion;
