/* eslint-disable camelcase */
/* eslint-disable react/no-danger */
import React from 'react';
import Head from 'next/head';
import useMetatagsData from '../../../hooks/useMetatagsData';

export default function StructuredDataJSON(props) {
   const {
      product,
      image,
      price,
      productID,
      color,
      slug,
      ratingValue,
      reviews,
      reviewCount,
      description,
   } = props;
   const { name, brand, sku } = product;
   const { STORE_DOMAIN } = useMetatagsData();

   const url = `${STORE_DOMAIN}/${slug}`;
   // La cantidad de valoraciones debe ser mayor a 0 para que se muestre
   const reviewData =
      reviewCount > 0
         ? {
              review: reviews?.map((review) => ({
                 author: { name: 'Usuario de Vaypol', '@type': 'Person' },
                 reviewBody: review.review,
                 reviewRating: { bestRating: 5, ratingValue: review.rating, worstRating: 1 },
              })),
              aggregateRating: {
                 '@type': 'AggregateRating',
                 ratingValue,
                 reviewCount,
              },
           }
         : {};
   return (
      <Head>
         <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
               __html: JSON.stringify({
                  '@context': 'http:\u002F\u002Fschema.org',
                  '@type': 'Product',
                  name,
                  image,
                  offers: {
                     price: (price.sale_price || price.original)
                        .replace(',00', '')
                        .replace(/\./, ''),
                     availability: 'http:\u002F\u002Fschema.org\u002FInStock',
                     url,
                     '@type': 'Offer',
                     priceCurrency: 'ARS',
                  },
                  ...reviewData,
                  brand,
                  sku,
                  description,
                  itemCondition: 'https:\u002F\u002Fschema.org\u002FNewCondition',
                  productID,
               }),
            }}
         />
      </Head>
   );
}
