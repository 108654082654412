/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import styles from './description.module.scss';

export default function Description({ text }) {
   return (
      <div className={styles.wrapper}>
         <div className={styles.description}>
            <div>
               <p>{text}</p>
            </div>
         </div>
      </div>
   );
}
