/* eslint-disable no-plusplus */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import Placeholder from 'Placeholder';
import styles from './placeholder-carousel.module.scss';

export default function PlaceholderCarousel() {
   return (
      <div className={styles.container}>
         <Placeholder className={styles.placeholder} />
         <Placeholder className={styles.placeholder} />
         <Placeholder className={styles.placeholder} />
         <Placeholder className={styles.placeholder} />
         <Placeholder className={styles.placeholder} />
      </div>
   );
}
