import { useEffect, useState } from 'react';
import cn from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { FiChevronRight } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import capitalize from '../../services/capitalize';
import verifyMicrositio from '../../services/filters/verifyMicrositio';
import style from './Breadcrumb.module.scss';

function objectToArrayOfLinks(object, lan, pathname) {
   const array = [];
   const { isMicrositio, micrositioName } = verifyMicrositio(pathname);
   const aditionalPath = `${isMicrositio ? `/${micrositioName}` : ''}`;
   const normalizeMessage = (text) => text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
   // Texto que se mostrará en el breadcrumb
   const visibleText = (text) => {
      switch (text) {
         case 'nino':
            return 'Niño';
         case 'munequeras-vinchas':
            return 'Muñequeras Vinchas';
         case 'futbol':
            return 'Fútbol';
         case 'natacion':
            return 'Natación';
         case 'botines-multitapon':
            return 'Botines Multitapón';
         case 'patin-patineta':
            return 'Patín Patineta';
         case 'adidas':
            return 'adidas';
         case 'adidas-running':
            return 'adidas Running';
         default:
            return capitalize(text, '-');
      }
   };

   const transformText = (text) => {
      return text
         ?.toLowerCase()
         .split(' ')
         .map((x) => normalizeMessage(x))
         .join('-');
   };
   if (lan === 'en') {
      object = {
         genero: transformText(object.gender),
         slugs: [transformText(object.category_name)],
         marca: [transformText(object.brand_name)],
         deporte: [transformText(object.sport)],
         categoria: transformText(object.business),
         landing: transformText(object.landing),
      };
   }

   const pushIfIsActive = (arg, arr) => {
      if (Array.isArray(arg)) {
         if (typeof arg[0] !== 'string' && arg[0]?.props.children.props.children) arr.push(...arg);
         else if (arg[0] && typeof arg[0] === 'string') arr.push(...arg);
      } else if (arg) {
         return object[arg]?.length > 0 ? arr.push(object[arg]) : '';
      }
      return 1;
   };
   pushIfIsActive('genero', array);
   pushIfIsActive('categoria', array);
   pushIfIsActive(object.slugs, array);
   pushIfIsActive(object.marca, array);
   pushIfIsActive(object.deporte, array);
   const arrayOfLinks = array.map((e, i) => (
      <Link key={e} href={{ pathname: `${aditionalPath}/${array.slice(0, i + 1).join('/')}` }}>
         <a className={style.link}>{visibleText(e)}</a>
      </Link>
   ));

   arrayOfLinks.unshift(
      <Link key="inicio" href={`${isMicrositio ? `/${micrositioName}` : '/'}`}>
         <a className={style.link}>Inicio</a>
      </Link>,
   );

   if (arrayOfLinks.length >= 1) {
      arrayOfLinks.splice(
         1,
         0,
         <Link key="productos" href={`${aditionalPath}/productos`}>
            <a className={style.link}>Productos</a>
         </Link>,
      );
      if (
         arrayOfLinks[2]?.props.href.pathname === `/${micrositioName ? `${micrositioName}/` : ''}`
      ) {
         arrayOfLinks[2].props.href.pathname = `/${
            micrositioName ? `${micrositioName}/` : ''
         }productos`;
      }
   }
   return arrayOfLinks;
}

function Breadcrumb({ aplicateIn, onlyMobile }) {
   const [brcrumb, setBrcrumb] = useState([]);
   const [_document, setDocument] = useState(null);
   const filtersActive = useSelector((state) => {
      return aplicateIn === 'productDetail'
         ? state.productDetail.product
         : state.products.filtersActive;
   });
   const classMobile = cn({
      [style.breadcrumb]: true,
      [style.mobile]: onlyMobile,
      [style.desktop]: !onlyMobile,
   });
   const { pathname } = useRouter();
   useEffect(() => {
      setBrcrumb(
         objectToArrayOfLinks(
            filtersActive,
            aplicateIn === 'productDetail' ? 'en' : 'es',
            pathname,
         ),
      );
   }, [filtersActive]);
   useEffect(() => {
      setDocument(document);
   }, []);
   const breadcrumbHtml = () => (
      <nav style={{ maxWidth: '1440px', margin: 'auto' }}>
         <ol className={classMobile}>
            {brcrumb.map((b, i) => {
               return (
                  <li className={style.item} key={b.key}>
                     {b}
                     {i < brcrumb.length - 1 && (
                        <div className={style.chevron}>
                           <FiChevronRight />
                        </div>
                     )}
                  </li>
               );
            })}
         </ol>
      </nav>
   );
   return (
      <>
         {(_document?.documentElement.clientWidth >= 768 && !onlyMobile) ||
         (_document?.documentElement.clientWidth < 768 && onlyMobile)
            ? breadcrumbHtml()
            : null}
      </>
   );
}
export default Breadcrumb;
