/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
import React, { useEffect } from 'react';
import { useMediaQuery } from '@react-hook/media-query';
import Badge from 'Badge';
import Brand from 'Brand';
import Button from 'Button';
import Fade from 'Fade';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { MdAutorenew, MdLocalShipping } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import Layout from '../../domain/Layout';
import ColorList from '../../domain/ProductDetail/ColorList';
import Description from '../../domain/ProductDetail/Description';
import Gallery from '../../domain/ProductDetail/Gallery';
import Price from '../../domain/ProductDetail/Price';
import Quantity from '../../domain/ProductDetail/Quantity';
import RelatedProducts from '../../domain/ProductDetail/RelatedProducts';
import SizeList from '../../domain/ProductDetail/SizeList';
import Specs from '../../domain/ProductDetail/Specs';
import StructuredDataProductDetail from '../../domain/ProductDetail/StructuredDataJSON';
import ProductHeader from '../../domain/ProductHeader';
import useBuildInfo from '../../hooks/useBuildInfo';
import useTrackingQueue from '../../hooks/useTrackingQueue';
import { addToCart, setQuantity } from '../../redux';
import createUUID from '../../services/createUUID';
import renderHeadDescription from '../../services/renderHeadDescription';
import styles from '../../styles/pages/product.module.scss';
import themes from '../../themes';
import Accordion from '../Accordion';
import Breadcrumb from '../Breadcrumb';
import PuntuationReview from '../PuntuationReview';
import Valorations from '../PuntuationReview/Valorations';

const CheckoutButton = dynamic(() =>
   import(/* webpackChunkName: "checkout-button" */ 'Cart/CheckoutButton'),
);
const Share = dynamic(() => import('../../domain/ProductDetail/Share'), { ssr: false });

export default function ProductDetailWrapper(props) {
   const { buildInfo } = props;
   const router = useRouter();
   const cart = useSelector((state) => state.cart);
   const site = useSelector((state) => state.site);
   const theme = useSelector((state) => state.theme.name);
   const productDetail = useSelector((state) => state.productDetail);
   const { product, variantSelected, quantity, id, colorSelected, slug, eventId } = productDetail;
   const dispatch = useDispatch();
   const { executeEvent } = useTrackingQueue();
   const isMobile = useMediaQuery('(max-width: 500px)');
   useBuildInfo(buildInfo);

   // Ejecutamos el evento de viewItem (analytics) / viewContent (pixel)
   // Ponemos como dependencia a props por si cambiamos de pagina a otro producto
   // (pero se sigue usando el mismo componente)
   useEffect(() => {
      executeEvent('viewProduct', { productDetail, eventId });
   }, [props]);

   const renderChangeBadges = () => {
      return (
         site.productBenefits &&
         site.productBenefits.map((b) => (
            <Badge
               backgroundColorLeft={
                  b.type === 'ship'
                     ? themes[theme]['color-prod-detail-badge-bg-ship']
                     : themes[theme]['color-prod-detail-badge-bg']
               }
               backgroundColorRight={
                  b.type === 'ship'
                     ? themes[theme]['color-prod-detail-badge-bg-ship']
                     : themes[theme]['color-prod-detail-badge-bg']
               }
               color={
                  b.type === 'ship'
                     ? themes[theme]['color-prod-detail-badge-text-ship']
                     : themes[theme]['color-prod-detail-badge-text']
               }
               className={styles.productBadges}
               icon={b.type === 'ship' ? <MdLocalShipping /> : <MdAutorenew />}
               key={b.label}
               // padding={0}
            >
               {b.label}
            </Badge>
         ))
      );
   };

   const handleClickCart = () => {
      const eventId = createUUID();
      dispatch(addToCart(isMobile, null, null, eventId));
      executeEvent('addToCart', { productDetail, eventId });
   };

   const handleSelectQuantity = (qty) => {
      dispatch(setQuantity(qty));
   };

   return (
      <Layout
         title={
            colorSelected.color
               ? `${colorSelected.name} - ${colorSelected.color}`
               : colorSelected.name
         }
         disableShare
      >
         <Head>
            <meta
               key="descriptionKey"
               name="description"
               content={renderHeadDescription(colorSelected.prices, product, colorSelected)}
            />

            {/* Facebook share */}
            <meta property="og:url" content={router.asPath} />
            <meta property="og:title" content={colorSelected.name} />
            <meta property="og:description" content={colorSelected.description} />
            <meta property="og:image" content={colorSelected.images[0]?.zoom.url} />
            <meta property="og:type" content="website" />

            {/* Twitter share */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:description" content={colorSelected.description} />
            <meta name="twitter:title" content={colorSelected.name} />
            <meta name="twitter:image" content={colorSelected.images[0]?.zoom.url} />
         </Head>
         <Breadcrumb aplicateIn="productDetail" />
         <StructuredDataProductDetail
            product={product}
            productID={id}
            color={colorSelected}
            slug={slug}
            image={colorSelected.images[0]?.zoom.url || null}
            price={colorSelected.prices}
            reviews={product.reviews}
            ratingValue={product.avg_rating}
            reviewCount={product.reviews_count}
            brand={product.brand_name}
            description={colorSelected.description}
         />

         <div className={styles.container}>
            {/* Gallery */}
            <div className={styles.gallery}>
               <Gallery productDetail={productDetail} isDesktop={!isMobile} />
               <Share title={colorSelected.name} text={colorSelected.description} mobile />
            </div>
            <div className={styles.wrapper}>
               {/* Title / Brand / Gender ... */}
               <div className={styles.wrapperTop}>
                  <ProductHeader
                     colorSelected={colorSelected}
                     gender={product.gender}
                     sport={product.sport}
                     featured={product.featured}
                  />
                  {product.brand_icon && (
                     <Brand brandName={product.brand_name} brandIcon={product.brand_icon.url} />
                  )}
               </div>
               {product.reviews_count ? (
                  <PuntuationReview viewMedia number={product.avg_rating} />
               ) : null}
               {/* TODO: El SKU debe cambiar según el color & talle elegido */}
               <div className={styles.sku}>SKU {colorSelected.sku}</div>
               {/* Price */}
               <div className={styles.price}>
                  <Price />
               </div>
               {/* Badges */}
               <div className={styles.badges}>
                  {/* TODO: Ver la forma de mandarle las variables de color ya creadas */}
                  {renderChangeBadges()}
               </div>
               {/* Color list */}
               <div className={styles.colorList}>
                  <ColorList colors={product.colors} />
               </div>
               {/* Size list */}
               <div className={styles.sizeList}>
                  <SizeList />
               </div>
               {/* Quantity */}
               {!isMobile ? (
                  <div className={styles.quantity}>
                     <h2 className={styles.titleQuantity}>Cantidad</h2>
                     <Quantity
                        value={quantity}
                        countOnHand={variantSelected?.count_on_hand || 0}
                        onSelect={handleSelectQuantity}
                     />
                  </div>
               ) : (
                  <div />
               )}
               {/* Button cart */}
               <div className={styles.buttonWrapper}>
                  <div className={styles.row}>
                     <Button
                        label="Agregar a carrito"
                        className={styles.addButton}
                        disabled={!variantSelected}
                        onClick={handleClickCart}
                        loading={cart.loading}
                     />
                     <Share title={colorSelected.name} text={colorSelected.description} />
                  </div>
                  {!variantSelected && (
                     <span className={styles.messageSize}>Selecciona un talle</span>
                  )}
               </div>
            </div>

            {/* Description / Properties */}
            <div className={styles.propertiesDescription}>
               {colorSelected.description && (
                  <Accordion title="Descripción">
                     <Description text={colorSelected.description} />
                  </Accordion>
               )}
               {colorSelected.properties?.length > 0 && (
                  <Accordion title="Propiedades">
                     <Specs properties={colorSelected.properties} />
                  </Accordion>
               )}
               {product.reviews_count ? (
                  <Accordion title="Opiniones">
                     <Valorations media={product.avg_rating} reviews={product.reviews} />
                  </Accordion>
               ) : null}
            </div>

            {/* Carousel */}
            <div className={styles.carousel}>
               <RelatedProducts productId={id} storeId={site.main.storeId} />
            </div>
         </div>

         {cart.item_count > 0 && !cart.showModal && (
            <div className={styles.wrapperCheckout}>
               <Fade className={styles.placeholder} show>
                  <CheckoutButton toCart />
               </Fade>
            </div>
         )}
         <Breadcrumb aplicateIn="productDetail" onlyMobile />
      </Layout>
   );
}
