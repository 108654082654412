/* eslint-disable react/no-array-index-key */
import React from 'react';
import styles from './specs.module.scss';

function Specs({ properties }) {
   const renderRows = () => {
      return properties.map((p, i) => (
         <tr key={i}>
            <th>{p[0]}</th>
            <td>{p[1]}</td>
         </tr>
      ));
   };

   return (
      <div className={styles.wrapper}>
         <div className={styles.table}>
            <table>
               <tbody>{renderRows()}</tbody>
            </table>
         </div>
      </div>
   );
}

export default Specs;
