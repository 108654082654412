import React from 'react';
import Placeholder from 'Placeholder';
import { useSelector } from 'react-redux';
import ColorItem from './ColorItem';
import styles from './color-list.module.scss';

function ColorList({ colors }) {
   const loading = useSelector((state) => state.productDetail.loading);

   const renderColors = () => {
      return colors.map((c) => <ColorItem key={c.id} color={c} />);
   };

   return (
      <div className={styles.wrapper}>
         <h2 className={styles.title}>Colores</h2>

         {/* TODO: @celeste arreglar placeholder */}
         {loading && (
            <Placeholder uniqueKey="placeholder-colors" className={styles.placeholder}>
               <rect x="0" y="0" rx="5" ry="5" width="50" height="50" />
               <rect x="65" y="0" rx="5" ry="5" width="50" height="50" />
               <rect x="130" y="0" rx="5" ry="5" width="50" height="50" />
               <rect x="195" y="0" rx="5" ry="5" width="50" height="50" />
            </Placeholder>
         )}

         {!loading && <ul className={styles.list}>{renderColors()}</ul>}
      </div>
   );
}

export default ColorList;
