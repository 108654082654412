/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import cn from 'classnames';
import router from 'next/router';
import { MdClose } from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';
import { setColor } from '../../../../redux/productDetail/productDetailActions';
import capitalize from '../../../../services/capitalize';
import styles from './color-item.module.scss';

function ColorItem({ color }) {
   const dispatch = useDispatch();
   const productDetail = useSelector((state) => state.productDetail);
   const selected = productDetail.colorSelected.id === color.id;
   const hasStock = color.in_stock;

   const handleClick = () => {
      if (hasStock) {
         dispatch(setColor(color));
      }
   };

   const classItem = cn({
      [styles.item]: true,
      [styles['item--active']]: selected,
      [styles['item--disabled']]: !hasStock,
   });

   return (
      <li title={color.color} className={classItem} onClick={handleClick}>
         <img
            src={`${color.images[0]?.thumbnail.url}`}
            alt={`${color?.name} - ${capitalize(color.color?.toLowerCase())}`}
         />
         {!hasStock && <MdClose className={styles.icon} />}
      </li>
   );
}

export default ColorItem;
