import PuntuationReview from 'PuntuationReview';
import style from './Valorations.module.scss';

function Valorations({ reviews, media }) {
   const createDate = (stringDate) => new Date(stringDate);

   return (
      <div className={style.valorations}>
         <div className={style.media}>
            <h3 className={style.title}>Promedio</h3>
            <PuntuationReview viewMedia number={media} />
         </div>

         {reviews.map((c) => (
            <article className={style.comment__review} key={c.id}>
               <h4>{c.title}</h4>
               <div>
                  <PuntuationReview number={c.rating} />
                  <p>{createDate(c.created_at).toLocaleDateString()}</p>
                  <span className={style.comment__user}> - </span>
                  <p className={style.comment__user} style={{ flex: 1 }}>
                     {c.name}
                  </p>
               </div>
               <p>{c.review}</p>
            </article>
         ))}
      </div>
   );
}
export default Valorations;
